<!--
 * @Description: 关键词排名
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-15 20:16:07
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/keyword/index.vue
-->
<template>
  <div class="app-wrapper wrapper-view">
    <div class="wrapper-title">关键词排名</div>
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      label-width="68px"
      style="margin-top: 20px"
    >
      <el-form-item label="公司名称" prop="company">
        <el-input
          style="width: 150px"
          v-model="queryParams.company"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
      <el-form-item label="关键词" prop="keyword">
        <el-input
          style="width: 150px"
          v-model="queryParams.keyword"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
      <el-form-item label="排名:" prop="ranking">
        <el-select
          v-model="queryParams.ranking"
          clearable
          placeholder="请选择"
          style="width: 150px"
        >
          <el-option label="10" :value="10"> </el-option>
          <el-option label="20" :value="20"> </el-option>
          <el-option label="30" :value="30"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="getList()"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      stripe
      max-height="620"
      style="width: 100%"
      v-loading="isLoading"
    >
      <el-table-column
        prop="keyword"
        show-overflow-tooltip
        label="关键词"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="ranking"
        show-overflow-tooltip
        label="排名"
        align="center"
      >
        <template slot-scope="{ row }">
          <span style="color: #4e6bef"
            >第<span style="color: red">{{ row.ranking }}</span
            >名</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="source"
        show-overflow-tooltip
        label="平台"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="company"
        show-overflow-tooltip
        label="公司名称"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="account_name"
        show-overflow-tooltip
        label="来源账号"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="video_title"
        show-overflow-tooltip
        label="视频标题"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="publish_at"
        show-overflow-tooltip
        label="视频发布时间"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="opts" label="操作" align="center" width="200">
        <template slot-scope="{ row }">
          <el-button size="mini" type="text" @click="handleOpts('preview', row)"
            >视频链接</el-button
          >
          <el-button size="mini" type="text" @click="handleOpts('release', row)"
            >搜索链接</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.size"
      @pagination="getList()"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../../utils/request";

const URL = {
    list: "api/operation/video/ranking",
  },
  QUERY = {
    company: null,
    ranking: null,
    keyword: null,
  };
export default {
  data() {
    return {
      queryParams: {
        ...QUERY,
      },
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    resetQuery() {
      this.queryParams = {
        ...QUERY,
      };
      this.getList(1);
    },
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const params = {
        ...this.queryParams,
      };
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
          ...params,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    handleOpts(type, row) {
      switch (type) {
        case "preview":
          window.open(row.video_link);
          break;
        case "release":
          window.open(row.search_link);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.warning {
  cursor: pointer;
}
</style>
